import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
 

import '../css/style.css'
import '../css/responsive.css'

import logo from '../images/logo.png'
import burger from '../images/burger.png';
import crosss from '../images/crosss.png';
 



const Header = () => {


  useEffect(() => {



    changePickupStoreMenu();

    function changePickupStoreMenu() {

      var body = $('body'),
        mask = $('<div class="mask"></div>'),
        toggleSlideRight = document.querySelector(".toggle-slide-right"),
        slideMenuRight = document.querySelector(".slide-menu-right"),
        activeNav = '';
      ;
      $('body').append(mask);

      /* slide menu right */
      toggleSlideRight.addEventListener("click", function () {
        $('body').addClass("smr-open");
        $('.mask').fadeIn();
        activeNav = "smr-open";
      });

      /* hide active menu if close menu button is clicked */
      $(document).on('click', ".close-menu", function (el, i) {
        $('body').removeClass(activeNav);
        activeNav = "";
        $('.mask').fadeOut();
      });

    }


  }, [])

  return (
    <div className="border-b">
      <div className="container-fluid">
        <div className="header-box">
          <div className="header-c1">
            <div className="logo-box">
              <a href="/">
                <img src={logo} />
              </a>
            </div>
          </div>


          <div className="header-c2">
            <ul className="menu-list-d">
             
                
                <li><Link to='#'>Home</Link></li>
                <li><Link to='#'>About</Link></li>
                <li><Link to='#'>Services</Link></li>
                  <li><Link to='#'>Blog</Link></li>
                  <li><Link to='#'>Contact</Link></li>
               
              
            </ul>
            <div className="burger-area">
              <a href="#" className="burgers toggle-slide-right"> <img src={burger} /></a>
            </div>
          </div>

        </div>

        <div className="menu slide-menu-right menu-list-wrp">
          <button class="close-menu"><img src={crosss} className="img-close" /></button>
          <ul className="menu-list2">

            <li>
              <ul className='nav-list'>
                 
                <li><Link to='#' class="close-menu">Home</Link></li>
                <li><Link to='#' class="close-menu">About</Link></li>
                <li><Link to='#' class="close-menu">Services</Link></li>
                  <li><Link to='#' class="close-menu">Blog</Link></li>
                  <li><Link to='#' class="close-menu">Contact</Link></li>

              </ul>
            </li>
          </ul>
        </div>

      </div>
    </div>
  );

}


export default Header;

