import React, { Component } from 'react';

import logo from '../images/logo.png';
import telegram from '../images/telegram.png';
import medium from '../images/medium.png';
import twitter from '../images/twitter.png';
import discord from '../images/discord.png'


class Footer extends Component {
  render() {
    return (
      <section id='footer'>
           
          <div className="footer-bg">
             <div className='row'>
                <div className='col-lg-4'>
                    <div className='flogo'>
                        <a href='/'>Your Logo Here</a>
                        <p>Copyright ©  2010-2022 </p>
                    </div>
                
                </div>
                <div className='col-lg-2'></div>
                <div className='col-lg-6'>
                <div className='f-social-icon'>
                    <div className='f-hding'>
                    <h3>Join the Community</h3>
                    </div>
                <ul className="social-icon">
                  <li>
                    <a href='JavaScript:void(0)'>
                      <img src={telegram}></img>
                    </a>
                  </li>
                   
                  <li>
                    <a href='JavaScript:void(0)'>
                      <img src={medium}></img>
                    </a>
                  </li>
                  <li>
                    <a href='JavaScript:void(0)'>
                      <img src={discord}></img>
                    </a>
                  </li>
                  <li>
                    <a href='JavaScript:void(0)'>
                      <img src={twitter}></img>
                    </a>
                  </li>
                </ul>
              </div>
                </div>
             </div>
          </div>
         
      </section>

    );
  }
}

export default Footer;