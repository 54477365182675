import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import './App.css';

import Home from './components/pages/home/Home'
 








function App() {


	return (
		<Router>
			<div>
				<Route exact path="/" name="Home Page" component={Home} />
				 
				 
			</div>
		</Router>
	);

}

export default App;
