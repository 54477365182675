module.exports = {
    CHAIN_ID: 1284 ,
    RPC_URL: 'https://bsc-dataseed1.binance.org/' ,
    // WIZARD_CONTRACT: '0x5066C68cAe3B9BdaCD6A1A37c90F2d1723559D18',
    // STAKING_CONTRACT: '0x0b4C44583F0Ed7E380D507ADE0e2fBb8cA3A5454',
    // MARKETPLACE: "0x00dFADA96eF7ecb0131e0d3cb36FB854B5cbbbC6" // Live 1
    MARKETPLACE: "0x7B1294C50758ed380C1ff9295fFB1797e33DE7E7", // Live 2
    EX_LINK: "https://moonbeam.moonscan.io/", // Live 2
    NFT_LINK: "https://moonbeam.moonscan.io/", // Live 2
    NFT_BUY : "0x42c4D8341B4B9F2fCCc3C46e37a08F6EDA0501F3",
    PRIVATE_SALE : "0xc7aE34e1284e0ce3cb372FFee656Ac27e2a504bb",
    API_URL : "https://game-api-address.herokuapp.com/api",
    GAME_COIN: "0xdD2d0B3D19F3b6d9840eE7d966304a55BD4246De",
    NFT_MAKER : "0x851473b02790d8FB12Db78caf9B24fEce2cB09c2"
    
    // MARKETPLACE: "0x7d5b7424A30832AF821b85546E02A8A4eDE2cEad" // Test 1
    // MARKETPLACE: "0x4f9907835A3af75F253BCc3BF7a51fa55eDf186c" // Test 2
    // MARKETPLACE: "0xE467884Bc129A73E7C55683D92179915C33F1B64" // Test 3
    // MARKETPLACE: "0xF82bA8577Fc27446f2f6986ADB2099Aea5710ECC" // Test 4
    // MARKETPLACE: "0x805b985Ef6E37b0340669AFbA5432Eb58491964E" // Test 5
    
    
}