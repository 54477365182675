import React from 'react';
import Header from '../header.js';
import Footer from '../footer'
import Bnr from './Bnr';
import vision from '../../images/vision.jpg'
import Slider from "react-slick";
import profileimg from '../../images/profileimg.png'
import apr1 from '../../images/apr1.png'
import seo from '../../images/seo.png'
import service from '../../images/service.png'
import idea from '../../images/idea.png'








function Home() {
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true,
					dots: true
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false
				}
			}
		]
	};
	return (
		<div>

			<Header />
			<section id='bnr-wrp'>

				<Bnr />

			</section>
			<section id='our-vision-wrp'>
				<div className='container-fluid'>
					<div className='our-vision-bg'>
						<div className='row'>
							<div className='col-lg-5'>
								<div className='vision-img'>
									<img src={vision} />
								</div>
							</div>
							<div className='col-lg-7'>
								<div className='our-vision'>
									<h1>Our Vision</h1>
									<p>As a team, we are always on the lookout for the next challenge we can take on<br />
										or the next problem to solve. Whenever we reach a new milestone or encounter<br /> a new challenge,
										we're always curious about how we can solve it or embrace it.<br /> There is always something
										new to learn or a next challenge to overcome. Please <br /> feel free to contact us if you have any questions. </p>
									<div className='meet'>
										<a href='#'>Schedule A Meet</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section id='services-wrp'>
				<div className='container-fluid'>


				</div>
			</section>
			<section id="opportunities-section">
				<div class="banner-overlay"></div>
				<div class="container">
					<div class="main-wrp-opr">
						<div class="opportunities-head">
							<h1>Our Services</h1>
						</div>
						<div class="row">

							<div class="col-lg-3">
								<div class="main">
									<div class="service">
										<div class="service-logo">
											<img src={apr1} />
										</div>
										<h4>Social Media Marketing</h4>
										<p>You will interact with people from diverse backgrounds and express your own ideas and opinions in front of diverse faces boosting your confidence level.</p>
									</div>
								</div>
							</div>

							<div class="col-lg-3">
								<div class="main">
									<div class="service">
										<div class="service-logo">
										<img src={service} />
										</div>
										<h4>Search Engine Marketing</h4>
										<p>Our Conferences will also help in improving your analytical skills, enabling you to solve any problem through analysis adding up to your skill levels</p>
									</div>
								</div>
							</div>

							<div class="col-lg-3">
								<div class="main">
									<div class="service">
										<div class="service-logo">
											
											<img src={seo} />
										</div>
										<h4>Search Engine Optimization</h4>
										<p>It is a very useful skill to possess when a purposeful conversation is going on between two groups or individuals. It is also known as the art of negotiation.</p>
									</div>
								</div>
							</div>
							<div class="col-lg-3">
								<div class="main">
									<div class="service">
										<div class="service-logo">
											<img src={idea} />
										</div>
										<h4>Creative Marketing</h4>
										<p>This modern era has put forth challenges that need to be thoughtfully resolved. This requires good leaders and it is our ambition to inculcate leadership skills.</p>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</section>

			<div className='testimonial'>

				<div className='container-fluid'>
					<div className='ow-hding'>
						<h1>Testimonial</h1>
					</div>
					<Slider {...settings}>
						<div>
							<div class="testimonial-item elementor-repeater-item-f8be11b tns-item tns-slide-cloned" aria-hidden="true" tabindex="-1">
								<div class="testimonial-box">
									<div class="testimonial-slider hover-from-left">
										<div class="icon-content"><i aria-hidden="true" class="fas fa-quote-left"></i></div><div class="comment-bio">
											<div className='pro-wrp'>
												<div className='profileimg'>
													<img src={profileimg} />
												</div>
												<span class="profile-info">
													<strong class="profile-name">Mark Richardson</strong>
													<p class="profile-des">CEO Vodoro</p>
												</span>
											</div>
										</div><div class="comment-content"><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p></div>
									</div>
								</div>
							</div>
						</div>

						<div>
							<div class="testimonial-item elementor-repeater-item-f8be11b tns-item tns-slide-cloned" aria-hidden="true" tabindex="-1">
								<div class="testimonial-box">
									<div class="testimonial-slider hover-from-left">
										<div class="icon-content"><i aria-hidden="true" class="fas fa-quote-left"></i></div><div class="comment-bio">
											<div className='pro-wrp'>
												<div className='profileimg'>
													<img src={profileimg} />
												</div>
												<span class="profile-info">
													<strong class="profile-name">Mark Richardson</strong>
													<p class="profile-des">CEO Vodoro</p>
												</span>
											</div>
										</div><div class="comment-content"><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p></div>
									</div>
								</div>
							</div>
						</div>

						<div>
							<div class="testimonial-item elementor-repeater-item-f8be11b tns-item tns-slide-cloned" aria-hidden="true" tabindex="-1">
								<div class="testimonial-box">
									<div class="testimonial-slider hover-from-left">
										<div class="icon-content"><i aria-hidden="true" class="fas fa-quote-left"></i></div><div class="comment-bio">
											<div className='pro-wrp'>
												<div className='profileimg'>
													<img src={profileimg} />
												</div>
												<span class="profile-info">
													<strong class="profile-name">Mark Richardson</strong>
													<p class="profile-des">CEO Vodoro</p>
												</span>
											</div>
										</div><div class="comment-content"><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p></div>
									</div>
								</div>
							</div>
						</div>

						<div>
							<div class="testimonial-item elementor-repeater-item-f8be11b tns-item tns-slide-cloned" aria-hidden="true" tabindex="-1">
								<div class="testimonial-box">
									<div class="testimonial-slider hover-from-left">
										<div class="icon-content"><i aria-hidden="true" class="fas fa-quote-left"></i></div><div class="comment-bio">
											<div className='pro-wrp'>
												<div className='profileimg'>
													<img src={profileimg} />
												</div>
												<span class="profile-info">
													<strong class="profile-name">Mark Richardson</strong>
													<p class="profile-des">CEO Vodoro</p>
												</span>
											</div>
										</div><div class="comment-content"><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p></div>
									</div>
								</div>
							</div>
						</div>


					</Slider>
				</div>
			</div>
			<section id='our-work-wrp'>
				<div className='container-fluid'>
					<div className='ow-hding'>
						<h1>Our Work</h1>
					</div>
					<div class="row">
						<div class="column">
							<div class="card">
								<div class="icon-wrapper">
									Cliff Hanger
								</div>
								<h3>Increased ROI by 31%</h3>
								<p><i class="fa fa-dot-circle-o" aria-hidden="true"></i> Optimized and Structured Marketing Funnel </p>
								<p><i class="fa fa-dot-circle-o" aria-hidden="true"></i> Full-Funnel Media Plan with rise in USPs strategy </p>
								<p><i class="fa fa-dot-circle-o" aria-hidden="true"></i> A/B Testing for Creatives</p>
							</div>
						</div>
						<div class="column">
							<div class="card">
								<div class="icon-wrapper">
								Qause
								</div>
								<h3>Improved CPA by 23% </h3>
								<p><i class="fa fa-dot-circle-o" aria-hidden="true"></i> Optimized and Structured Marketing Funnel</p>
								<p><i class="fa fa-dot-circle-o" aria-hidden="true"></i> Full-Funnel Media Plan with rise in USPs strategy </p>
								<p><i class="fa fa-dot-circle-o" aria-hidden="true"></i> Focused on Display Activity</p>
							</div>
						</div>
						<div class="column">
							<div class="card">
								<div class="icon-wrapper">
								Fidoo
								</div>
								<h3>Increased ROI by 42% </h3>
								<p><i class="fa fa-dot-circle-o" aria-hidden="true"></i> Optimized and Structured Marketing Funnel</p>
								<p><i class="fa fa-dot-circle-o" aria-hidden="true"></i> Full-Funnel Media Plan with rise in USPs strategy</p>
								<p><i class="fa fa-dot-circle-o" aria-hidden="true"></i> Experiments</p>
							</div>
						</div>
						
					</div>


				</div>
			</section>
			<section id='letus'>
				<div className='container-fluid'>
					<div className='letus-box'>
						<div className='letus-hding'>
							<h1>Let us take the marketing weight off your shoulders</h1>
						</div>
						<div>
							<div className='b-call'>
								<a href='#'>Book a Call</a>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</div>
	);
}


export default Home;